export const inspectedSliderData = [
  { imgLink: "./images/company/inspected/C1.png" },
  { imgLink: "./images/company/inspected/C2.png" },
  { imgLink: "./images/company/inspected/C3.png" },
  { imgLink: "./images/company/inspected/C4.png" },
  { imgLink: "./images/company/inspected/C5.png" },
  { imgLink: "./images/company/inspected/C6.png" },
  { imgLink: "./images/company/inspected/C7.png" },
  { imgLink: "./images/company/inspected/C8.png" },
  { imgLink: "./images/company/inspected/C9.png" },
  { imgLink: "./images/company/inspected/C10.png" },
  { imgLink: "./images/company/inspected/C11.png" },
  { imgLink: "./images/company/inspected/C12.png" },
  { imgLink: "./images/company/inspected/C13.png" },
  { imgLink: "./images/company/inspected/C14.png" },
  { imgLink: "./images/company/inspected/C15.png" },
  { imgLink: "./images/company/inspected/C16.png" },
];

export const approvedSliderData = [
  { imgLink: "./images/company/approved/BHARATPETROL.png" },
  { imgLink: "./images/company/approved/BHEL.png" },
  { imgLink: "./images/company/approved/EIL.png" },
  { imgLink: "./images/company/approved/GAIL.png" },
  { imgLink: "./images/company/approved/HP.png" },
  { imgLink: "./images/company/approved/GUJARATGAS.png" },
  { imgLink: "./images/company/approved/IGL.png" },
  { imgLink: "./images/company/approved/INDIANOIL.png" },
  { imgLink: "./images/company/approved/LT.png" },
  { imgLink: "./images/company/approved/NTPC.png" },
  { imgLink: "./images/company/approved/PDIL.png" },
  { imgLink: "./images/company/approved/TCE.png" },
  { imgLink: "./images/company/approved/C1.png" },
  { imgLink: "./images/company/approved/C2.png" },
  { imgLink: "./images/company/approved/C3.png" },
  { imgLink: "./images/company/approved/C4.png" },
  { imgLink: "./images/company/approved/C5.png" },
  { imgLink: "./images/company/approved/C6.png" },
  { imgLink: "./images/company/approved/C7.png" },
  { imgLink: "./images/company/approved/C8.png" },
  { imgLink: "./images/company/approved/C9.png" },
  { imgLink: "./images/company/approved/C10.png" },
  { imgLink: "./images/company/approved/C11.png" },
  { imgLink: "./images/company/approved/C12.png" },
  { imgLink: "./images/company/approved/C13.png" },
  { imgLink: "./images/company/approved/C14.png" },
  { imgLink: "./images/company/approved/C15.png" },
  { imgLink: "./images/company/approved/C16.png" },
  { imgLink: "./images/company/approved/C17.png" },
  { imgLink: "./images/company/approved/C18.png" },
  { imgLink: "./images/company/approved/C19.png" },
  { imgLink: "./images/company/approved/C20.png" },
  { imgLink: "./images/company/approved/C21.png" },
  { imgLink: "./images/company/approved/C22.png" },
];
