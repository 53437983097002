export const groupActivities = [
  {
    name: "ITL Public School",
    imgURI: "./images/activities/school/1.jpg",
    redirectURL: "/itl-public-school",
    content: {
      headline: "ITL Public School, Dwarka",
      desc: "Indus Tubes Limited demonstrates a strong commitment to education through ITL Public School, a premier institution located in Dwarka, New Delhi.",
      subcontents: [
        {
          subTitle: "Affiliation",
          desc: "The school is affiliated with the Central Board of Secondary Education (CBSE)",
        },

        {
          subTitle: "Student and Teacher Strength",
          desc: "ITL Public School serves a vibrant community of 3,000 students and 300 dedicated educators.",
        },

        {
          subTitle: "Facilities and Reputation",
          desc: "Known for its modern infrastructure and holistic educational approach, the school emphasizes academic excellence and extracurricular development, making it one of the leading institutions in the area",
        },
      ],
      imagesURIs: [
        "./images/activities/school/1.jpg",
        "./images/activities/school/2.jpg",
        "./images/activities/school/3.jpg",
        "./images/activities/school/4.jpg",
        "./images/activities/school/5.jpg",
        "./images/activities/school/6.jpg",
      ],
    },
  },
  {
    name: "ITL Educations and Solutions ",
    imgURI: "./images/activities/education/1.jpg",
    redirectURL: "/itl-education",
    content: {
      headline: "ITL Educations and Solutions (Publishing Business)",
      desc: "The publishing arm of ITL, ITL Educations and Solutions, caters to the academic sector with high-quality publications and learning materials.",
      subcontents: [
        {
          subTitle: "Clientele",
          desc: "The division has established partnerships with esteemed clients like Tata McGraw Hill and several other leading publishers.",
        },

        {
          subTitle: "Services",
          desc: "ITL provides a comprehensive range of services, including content development, publishing, and distribution, contributing to the academic community with impactful educational resources.",
        },
      ],
      imagesURIs: ["./images/activities/education/2.jpg"],
    },
  },
  {
    name: "Shri Siddheshwar Dham",
    imgURI: "./images/activities/dham/1.jpg",
    redirectURL: "/shri-siddeeshwar-dham",
    content: {
      headline: "Construction of Sri Siddeeshwar Dham, Namchi, Sikkim",
      desc: "One of ITL’s most notable contributions to cultural and spiritual heritage is the construction of the Sri Siddeeshwar Dham Mandir in Namchi, Sikkim.",
      subcontents: [
        {
          subTitle: "Project Scope",
          desc: "This monumental project, commissioned by the Government of Sikkim, involved constructing a temple complex that has become a significant spiritual and tourist attraction",
        },
        {
          subTitle: "Significance",
          desc: "The temple underscores ITL’s ability to deliver large-scale projects that blend engineering excellence with cultural significance.",
        },
      ],
      imagesURIs: [
        "./images/activities/dham/1.jpg",
        "./images/activities/dham/2.jpg",
        "./images/activities/dham/3.jpg",
        "./images/activities/dham/4.jpg",
        "./images/activities/dham/5.jpg",
        "./images/activities/dham/6.jpg",
        "./images/activities/dham/7.jpg",
        "./images/activities/dham/8.jpg",
      ],
    },
  },
];
