import React from "react";
import "./Heading.css";

const Heading = (props) => {
  return (
    <div className="heading__container">
      {props.capsText && <span className="caps__title">{props.capsText}</span>}
      {props.highlightText && (
        <span className="highlight__title">{props.highlightText}</span>
      )}
      {props.subTitleText && (
        <span
          style={{ textAlign: "center", fontSize: "14px" }}
          className="sub__title"
        >
          {props.subTitleText}
        </span>
      )}
    </div>
  );
};

export default Heading;
