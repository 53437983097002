import React, { useState, useEffect } from "react";
import "./About.css";
import Heading from "../../Components/Heading/Heading";
import ZCard from "../../Components/ZCard/ZCard";
import { aboutMainText, secondaryAboutText } from "../../assets/about";
import Loader from "../../Components/Loader/Loader";

const About = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div className="about__container">
      <Heading highlightText="About" subTitleText="Know more about us" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ZCard alt="company" imgSrc="./company.jpg" text={aboutMainText} />
          <ZCard
            alt="tubes"
            imgSrc="./tubes.jpg"
            text={secondaryAboutText}
            reverse
          />
        </>
      )}
    </div>
  );
};

export default About;
