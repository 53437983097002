import React from "react";
import Slider from "react-slick";
import "./ActivityCard.css";
import SubContent from "./SubContent";

const ActivityCard = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //console.log("Abhishek Nayak", props.data.content);
  return (
    <div className="activity___contents">
      {/* Carousel */}
      {props.data.content.imagesURIs.length > 1 ? (
        <div className="carousel___container">
          <Slider {...settings}>
            {props.data.content.imagesURIs.map((uri, ind) => {
              return <img key={ind} src={uri} alt={ind} />;
            })}
          </Slider>
        </div>
      ) : (
        <div className="activity___single__img_container">
          <img
            src={props.data.content.imagesURIs[0]}
            alt={props.data.content.headline}
          />
        </div>
      )}

      <div className="activity___content___container">
        <h2 className="activity___headline wow fadeInUp">
          {props.data.content.headline}
        </h2>
        <p className="activity___desc wow fadeInUp">
          {props.data.content.desc}
        </p>

        <div className="activity__sub__content_container">
          {props.data.content.subcontents.map((el, ind) => {
            return <SubContent key={ind} title={el.subTitle} desc={el.desc} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
