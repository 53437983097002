import React from "react";
import "./Products.css";
import { hollowTable } from "../../assets/productsTables";

const STHollow = () => {
  return (
    <div className="products__table_container">
      <div className="table__heading_container">
        <h2 className="table__main_heading">
          HOLLOW STEEL SECTIONS FOR STRUCTURAL AS PER IS: 4923 : 1997
        </h2>
        <h3 className="table__sub_heading">SQUARE HOLLOW SECTIONS (SHS)</h3>
        <table className="product__table">
          <tr>
            <th>Sections SHS (mm)</th>
            <th>Depth D(mm)</th>
            <th>Width B(mm) </th>
            <th>Thickness T(mm) </th>
            <th>Weight W(Kg/m)</th>
            <th>Mts/Tonne</th>
          </tr>

          {hollowTable.square.map((el) => {
            return (
              <tr>
                <td>{el[0]}</td>
                <td>{el[1]}</td>
                <td>{el[2]}</td>
                <td>{el[3]}</td>
                <td>{el[4]}</td>
                <td>{el[5]}</td>
              </tr>
            );
          })}
        </table>

        <h3 className="table__sub_heading">
          RECTENGULAR HOLLOW SECTIONS (RHS)
        </h3>
        <table className="product__table">
          <tr>
            <th>Sections SHS (mm)</th>
            <th>Depth D(mm)</th>
            <th>Width B(mm) </th>
            <th>Thickness T(mm) </th>
            <th>Weight W(Kg/m)</th>
            <th>Mts/Tonne</th>
          </tr>

          {hollowTable.rectangle.map((el) => {
            return (
              <tr>
                <td>{el[0]}</td>
                <td>{el[1]}</td>
                <td>{el[2]}</td>
                <td>{el[3]}</td>
                <td>{el[4]}</td>
                <td>{el[5]}</td>
              </tr>
            );
          })}
        </table>

        <table className="product__table">
          <tr>
            <th>GRADE</th>
            <th>T.S (Min) Mpa</th>
            <th>Y.S (Min) Mpa</th>
            <th>Elongation Under 25.4 mm</th>
            <th>Elongation Over 25.4 mm</th>
          </tr>

          {hollowTable.other.map((el) => {
            return (
              <tr>
                <td>{el[0]}</td>
                <td>{el[1]}</td>
                <td>{el[2]}</td>
                <td>{el[3]}</td>
                <td>{el[4]}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default STHollow;
