import React from "react";

const SubContent = (props) => {
  return (
    <div className="activity__subtitle__row wow wow fadeInUp">
      <h3>{props.title}</h3>
      <p>{props.desc}</p>
    </div>
  );
};

export default SubContent;
