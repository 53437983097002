import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [navClicked, setNavClicked] = useState(false);
  const [productClicked, setProductClicked] = useState(false);
  const [listOfProductClicked, setListOfProductClicked] = useState(false);

  const closeNavHandler = () => {
    setNavClicked(false);
    setProductClicked(false);
    setListOfProductClicked(false);
  };

  return (
    <header className="header">
      <Link to="/" onClick={closeNavHandler}>
        <img src="./logo.png" alt="logo" className="logo" />
      </Link>

      <nav className="nav">
        <ul
          className={`nav__links_container ${navClicked ? "navBarActive" : ""}`}
        >
          <li>
            <Link className="nav__link" to="/" onClick={closeNavHandler}>
              Home
            </Link>
          </li>

          <li>
            <Link className="nav__link" to="/about" onClick={closeNavHandler}>
              About
            </Link>
          </li>
          <li style={{ position: "relative", cursor: "pointer" }}>
            <span
              className="nav__link"
              onClick={() => {
                setProductClicked((prev) => {
                  setListOfProductClicked(false);
                  return !prev;
                });
              }}
            >
              Products
            </span>

            {productClicked && (
              <div className="products_menu">
                <Link
                  className="sub_nav__link"
                  to="/ms-pipes"
                  onClick={closeNavHandler}
                >
                  MS Pipes
                </Link>

                <Link
                  className="sub_nav__link"
                  to="/gi-pipes"
                  onClick={closeNavHandler}
                >
                  GI Pipes
                </Link>

                <div
                  style={{ position: "relative" }}
                  className="sub_nav__link"
                  onClick={() => {
                    setListOfProductClicked((prev) => !prev);
                  }}
                >
                  List of Products
                  {listOfProductClicked && (
                    <div className="sub_products_menu">
                      <Link
                        className="sub_nav__link"
                        to="/steel-tubes-for-water-gas-steam-air"
                        onClick={closeNavHandler}
                      >
                        (S.T) Wtr, Gas, Steam & Air
                      </Link>

                      <Link
                        className="sub_nav__link"
                        to="/steel-tubes-for-structural-scffolding"
                        onClick={closeNavHandler}
                      >
                        (S.T) Structural Scffolding
                      </Link>

                      <Link
                        className="sub_nav__link"
                        to="/steel-tubes-for-transport-system-for-png"
                        onClick={closeNavHandler}
                      >
                        Transport System for PNG
                      </Link>

                      <Link
                        className="sub_nav__link"
                        to="/steel-tubes-for-water-gas-and-sewerage"
                        onClick={closeNavHandler}
                      >
                        (S.T) Water Gas & Sewerage
                      </Link>

                      <Link
                        className="sub_nav__link"
                        to="/steel-tubes-for-idlers-and-troughed"
                        onClick={closeNavHandler}
                      >
                        (S.T) Idlers & Troughed & (W.W)
                      </Link>

                      <Link
                        className="sub_nav__link"
                        to="/steel-tubes-for-hollow-stl-sec"
                        onClick={closeNavHandler}
                      >
                        Hollow STL sec. for Structural
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}
          </li>
          <li>
            <Link
              className="nav__link"
              to="/group-activities"
              onClick={closeNavHandler}
            >
              Group Activities
            </Link>
          </li>
          <li>
            <Link
              className="nav__link"
              to="/certifications"
              onClick={closeNavHandler}
            >
              Certifications
            </Link>
          </li>
          <li>
            <Link className="nav__link" to="/contact" onClick={closeNavHandler}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <div
        className={`burger ${navClicked ? "toggle" : ""}`}
        onClick={() => setNavClicked((prev) => !prev)}
      >
        <div className="line line__1"></div>
        <div className="line line__2"></div>
        <div className="line line__3"></div>
      </div>
    </header>
  );
};

export default Header;
