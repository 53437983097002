export const aboutMainText = [
  "“Indus Tubes” belongs to a well established and professionally managed ITL group. The group turn-over is more than Rs.200 crores (US$ 45 Millions.), with group manpower strength around 1000. The group’s activities include of Steel Tubes manufacturing, Electricity Poles, Transmission Lines, Transformers, Telecom Towers, Construction, Real Estates, InformationTechnology, Education and International Business etc",
  "“Indus Tubes” manufacture Steel Tubes in MS & Galvanised qualities and in sizes 15mm to 350mm. The products are covered under BIS & many international specifications. The Co. is an ISO:9001. It’s plant is located at Village Chhaprola, in Gautam Budh Nagar (Ghaziabad.-U.P.) approx. 35 Km from Delhi on Delhi Kolkata National Highway. The Plant is spread over 28000 square metres. It is equipped with most modern, sophisticated & automatic ERW High Frequency Induction Welding-World’s latest Technology. 3 Tube manufacturing mills, Galvanising Plant, Slitting Line, Hydro Testing equipment, finishing Machines, Threading & Socket Machines, STP Plant and Quality Control Lab etc",
  "“Indus Tubes” employs qualified, skilled & experienced personnel, totaling about 300. It’s design & development wing has made vital contributions in producing customized products & improvements in tube related areas",
];

export const secondaryAboutText = [
  "Tubes are manufactured from mild steel strips slitted from H.R. coils confirming to IS: 10748-1984 and other national & international specifications. The strip passes through a series of driven forming and finrolls and is given the desired circular shape and is welded continuously by the passage of an electric current having a frequency of about 4,50,000 cycles/second at 11000 to 14000 Volts After the excess weld bead has been removed, the pipe passes through a set of sizing rolls where it is rolled to the correct outside diameter. The pipes are than cut into required lengths by automatic cutting machines. Then these go to automatic Galvanizing line. The uniform coating as per BIS 4736-1986 (over 400g/M2) on average is maintained. From here, the tubes travel to finishing line, where those are threaded, socketed & fitted with PVC protective rings and then packed in Bundles of 1 or 2 Tons & batch numbers given.",
  "Company has a well equipped laboratory for analysis of various elements and having various machines viz.",
  "Tensile and Harness testing machines.",
  "Bend, expansion and flatness test facilities.",
  "Load testing apparatus",
  "Hydraulic pressure test arrangement.",
  "Eddy Current Tester.",
  "Stripping & Uniformity Galvanised Test.",
  "Various gauges & instruments.",
];
