import React from "react";
import "./Products.css";
import { pngTable } from "../../assets/productsTables";

const TransportSystemPng = () => {
  return (
    <div className="products__table_container">
      <div className="table__heading_container">
        <h2 className="table__main_heading">
          STEEL PIPES FOR PIPELINE TRANSPORTATION SYSTEM FOR PETROLEUM AND
          NATURAL GAS INDUSTRIES
        </h2>
        <h3 className="table__sub_heading">
          CONFORMING TO IS/ISO:3183: 2007(IMPROVED VERSION OF IS-1978) PSL -1
          GRADE A & B
        </h3>
      </div>

      <table className="product__table">
        <tr>
          <th>N.B (mm)</th>
          <th>O.D (mm)</th>
          <th>Wall Thickness (mm)</th>
          <th>Mass of P/E pipe (Kg/Mtr)</th>
          <th>Mtr/Tonne</th>
          <th>Standard test presure (Mpa)</th>
        </tr>

        {pngTable.map((el) => {
          return (
            <tr>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{el[2]}</td>
              <td>{el[3]}</td>
              <td>{el[4]}</td>
              <td>{el[5]}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default TransportSystemPng;
