export const products = {
  msPipes: {
    headline: "Manufacturing Process of MS Pipes",
    desc: "MS pipes are primarily made from mild steel, known for its durability and machinability. The process involves the following steps:",
    content: [
      {
        headline: "A. Raw Material Preparation",
        sub_conents: [
          {
            headline: "Steel Slabs or Coils",
            desc: "Raw material (hot-rolled steel slabs or coils) is procured",
          },
          {
            headline: "Inspection",
            desc: "The raw material is inspected for defects and quality.",
          },
        ],
      },
      {
        headline:
          "B. Pipe Formation (ERW Process - Electric Resistance Welding)",
        sub_conents: [
          {
            headline: "Slitting",
            desc: "The steel coil is slit into narrower strips of the desired width.",
          },
          {
            headline: "Forming",
            desc: "The steel strip is passed through a series of rollers to gradually bend it into a cylindrical shape.",
          },
          {
            headline: "Welding",
            desc: "The edges of the strip are welded together using high-frequency electrical resistance. This creates a continuous seam, forming a hollow cylindrical pipe.",
          },
          {
            headline: "Seam Annealing",
            desc: "The welded seam is heat-treated to relieve stresses and ensure structural uniformity.",
          },
        ],
      },
      {
        headline: "C. Sizing and Cutting",
        sub_conents: [
          {
            headline: "Sizing Mill",
            desc: "The welded pipe passes through sizing rollers to achieve the desired diameter and roundness.",
          },
          {
            headline: "Cutting",
            desc: "The continuous pipe is cut to the required lengths using automated cutting machines",
          },
        ],
      },
      {
        headline: "D. Heat Treatment",
        desc: "The pipes may undergo heat treatment to enhance mechanical properties, depending on the application.",
      },
      {
        headline: "E. Surface Treatment",
        sub_conents: [
          {
            headline: "Descaling",
            desc: "Pipes are cleaned using acid pickling or abrasive cleaning to remove mill scale, rust, or other contaminants.",
          },
          {
            headline: "Coating ",
            desc: "Pipes may be coated with oil, varnish, or paint for rust prevention.",
          },
        ],
      },
      {
        headline: "F. Quality Inspection",
        sub_conents: [
          {
            headline: "Non-Destructive Testing (NDT)",
            desc: "Techniques such as ultrasonic testing or eddy current testing are used to check for defects.",
          },
          {
            headline: "Dimensional Checks",
            desc: "Ensures pipes meet the required specifications.",
          },
        ],
      },
      {
        headline: "G. Packaging",
        desc: "Pipes are bundled and packed for transportation",
      },
    ],
  },
  giPipes: {
    headline: "Manufacturing Process of GI Pipes",
    desc: "GI pipes are MS pipes coated with a layer of zinc to enhance corrosion resistance. The process involves the following additional steps after manufacturing MS pipes:",
    content: [
      {
        headline: "A. Cleaning",
        sub_conents: [
          {
            headline: "Surface Preparation",
            desc: "The MS pipes are cleaned thoroughly using acid pickling to remove grease, oil, rust, and mill scale. Pipes are rinsed with water to ensure a clean surface for galvanizing.",
          },
        ],
      },
      {
        headline: "B. Preheating",
        desc: "The cleaned pipes are preheated to remove any residual moisture and improve the adhesion of zinc.",
      },
      {
        headline: "C. Galvanizing",
        sub_conents: [
          {
            headline: "Hot-Dip Galvanizing",
            desc: "Pipes are dipped into a bath of molten zinc maintained at around 450°C (842°F). The zinc forms a metallurgical bond with the steel, creating a protective coating.",
          },
          {
            headline: "Centrifugal Spinning ",
            desc: "Excess zinc is removed from the pipe's interior and exterior by spinning, ensuring uniform thickness.",
          },
        ],
      },
      {
        headline: "D. Cooling and Quenching",
        desc: "The galvanized pipes are cooled rapidly, usually in water or air, to solidify the zinc coating.",
      },
      {
        headline: "E. Surface Finishing",
        sub_conents: [
          {
            headline: "Passivation ",
            desc: " Pipes may be treated with a chromate solution to enhance corrosion resistance and improve appearance.",
          },
          {
            headline: "Inspection",
            desc: "Coating thickness and adherence are checked using magnetic gauges and visual inspection.",
          },
        ],
      },
      {
        headline: "F. Threading and Coupling",
        desc: "GI pipes may be threaded at the ends and fitted with couplings, depending on customer requirements.",
      },
      {
        headline: "G. Quality Control",
        sub_conents: [
          {
            headline: "Coating Tests",
            desc: "The zinc coating is tested for thickness, uniformity, and adherence.",
          },
          {
            headline: "Pressure Testing",
            desc: "Pipes are tested for leakage under pressure.",
          },
        ],
      },
      {
        headline: "H. Packaging and Dispatch",
        desc: "The finished GI pipes are bundled and packed for shipment.",
      },
    ],
  },
};
