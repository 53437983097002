export const sewerageTable = [
  [150, 168.3, 4.0, 16.21, 61.69],
  [150, 168.3, 4.5, 18.18, 55.0],
  [150, 168.3, 4.8, 19.35, 51.68],
  [150, 168.3, 5.0, 20.12, 49.68],
  [150, 168.3, 5.4, 21.69, 46.1],
  [150, 168.3, 6.0, 24.01, 41.65],
  [150, 168.3, 6.4, 25.55, 39.13],
  [150, 168.3, 7.0, 27.84, 35.92],
  [150, 168.3, 7.1, 28.22, 35.42],
  [150, 168.3, 8.0, 31.62, 31.62],
  [175, 193.7, 4.0, 18.71, 53.45],
  [175, 193.7, 4.5, 21.0, 47.62],
  [175, 193.7, 4.8, 22.36, 44.72],
  [175, 193.7, 5.0, 23.27, 42.97],
  [175, 193.7, 5.4, 25.07, 39.89],
  [175, 193.7, 6.0, 27.77, 36.01],
  [175, 193.7, 6.4, 29.56, 33.83],
  [175, 193.7, 7.0, 32.23, 31.03],
  [175, 193.7, 8.0, 36.63, 27.3],
  [200, 219.1, 4.0, 21.22, 47.13],
  [200, 219.1, 4.5, 23.81, 42.0],
  [200, 219.1, 4.8, 25.37, 39.42],
  [200, 219.1, 5.0, 26.4, 37.88],
  [200, 219.1, 5.4, 28.46, 35.14],
  [200, 219.1, 6.0, 31.53, 31.72],
  [200, 219.1, 6.4, 33.57, 29.79],
  [200, 219.1, 7.0, 36.61, 27.31],
  [200, 219.1, 8.0, 41.65, 24.0],
  [200, 219.1, 9.0, 46.63, 21.44],
  [200, 219.1, 9.5, 49.1, 20.36],
  [250, 273, 4.5, 29.8, 33.56],
  [250, 273, 4.8, 31.75, 31.49],
  [250, 273, 5.0, 33.04, 30.27],
  [250, 273, 5.4, 35.63, 28.07],
  [250, 273, 6.0, 39.5, 25.32],
  [250, 273, 6.4, 42.07, 23.77],
  [250, 273, 7.0, 45.91, 21.78],
  [250, 273, 8.0, 52.28, 19.13],
  [250, 273, 9.0, 58.59, 17.07],
  [250, 273, 9.5, 61.73, 16.2],
  [300, 323.9, 5.0, 39.32, 25.47],
  [300, 323.9, 5.4, 42.4, 23.58],
  [300, 323.9, 6.0, 47.0, 21.27],
  [300, 323.9, 6.4, 50.11, 19.95],
  [300, 323.9, 7.0, 54.7, 18.28],
  [300, 323.9, 8.0, 62.3, 16.05],
  [300, 323.9, 9.0, 69.89, 14.38],
  [300, 323.9, 9.5, 73.65, 13.58],
  [350, 355.6, 5.0, 43.23, 23.13],
  [350, 355.6, 5.4, 46.63, 21.44],
  [350, 355.6, 6.0, 51.72, 19.33],
  [350, 355.6, 6.4, 55.11, 18.14],
  [350, 355.6, 7.0, 60.17, 16.61],
  [350, 355.6, 8.0, 68.57, 14.58],
  [350, 355.6, 9.0, 76.92, 12.99],
  [350, 355.6, 9.5, 81.08, 12.33],
];

export const pngTable = [
  [80, 88.9, 2.1, 4.5, 222.22, 5.9],
  [80, 88.9, 2.8, 5.95, 168.06, 5.9],
  [80, 88.9, 3.2, 6.76, 147.93, 8.9],
  [80, 88.9, 3.6, 7.57, 132.1, 8.9],
  [80, 88.9, 4.0, 8.37, 119.47, 11.2],
  [80, 88.9, 4.4, 9.17, 109.05, 12.3],
  [80, 88.9, 4.8, 9.95, 100.5, 13.4],
  [80, 88.9, 5.5, 11.31, 88.42, 15.4],
  [80, 88.9, 6.4, 13.02, 76.8, 17.2],
  [90, 101.6, 2.1, 5.15, 194.17, 5.1],
  [90, 101.6, 2.8, 6.82, 146.82, 6.8],
  [90, 101.6, 3.2, 7.76, 128.86, 7.8],
  [90, 101.6, 3.6, 8.7, 114.94, 8.8],
  [90, 101.6, 4.0, 9.63, 103.84, 9.8],
  [90, 101.6, 4.4, 10.55, 94.79, 10.8],
  [90, 101.6, 4.8, 11.46, 87.26, 11.7],
  [90, 101.6, 5.7, 13.48, 74.18, 13.9],
  [90, 101.6, 6.4, 15.02, 66.58, 15.6],
  [100, 114.3, 2.1, 5.81, 172.12, 4.6],
  [100, 114.3, 2.8, 7.7, 129.87, 6.1],
  [100, 114.3, 3.2, 8.77, 114.03, 7.0],
  [100, 114.3, 3.6, 9.83, 101.73, 7.8],
  [100, 114.3, 4.0, 10.88, 91.91, 8.7],
  [100, 114.3, 4.4, 11.92, 83.89, 9.6],
  [100, 114.3, 4.8, 12.96, 77.16, 10.4],
  [100, 114.3, 5.2, 13.99, 71.48, 11.3],
  [100, 114.3, 5.6, 15.01, 66.62, 12.2],
  [100, 114.3, 6.0, 16.02, 66.42, 13],
  [100, 114.3, 6.4, 17.03, 58.72, 13.9],
  [100, 114.3, 7.1, 18.77, 53.28, 15.4],
  [100, 114.3, 7.9, 20.73, 48.24, 17.2],
  [125, 141.3, 2.1, 7.21, 138.7, 3.7],
  [125, 141.3, 3.2, 10.9, 91.74, 5.6],
  [125, 141.3, 4.0, 13.94, 71.74, 7.0],
  [125, 141.3, 4.8, 16.16, 61.88, 8.4],
  [125, 141.3, 5.6, 18.74, 53.36, 9.6],
  [125, 141.3, 6.6, 21.92, 45.62, 11.6],
  [125, 141.3, 7.1, 23.5, 42.55, 12.5],
  [125, 141.3, 7.9, 25.99, 38.48, 13.9],
  [150, 168.3, 2.8, 11.43, 87.49, 4.1],
  [150, 168.3, 3.2, 13.03, 76.75, 4.7],
  [150, 168.3, 3.6, 14.62, 67.4, 5.3],
  [150, 168.3, 4.0, 16.21, 61.69, 5.9],
  [150, 168.3, 4.4, 17.78, 56.24, 6.5],
  [150, 168.3, 4.8, 19.35, 51.68, 7.1],
  [150, 168.3, 5.2, 20.91, 47.82, 7.7],
  [150, 168.3, 5.6, 22.47, 44.5, 8.3],
  [150, 168.3, 6.4, 25.55, 39.14, 9.4],
  [150, 168.3, 7.1, 28.22, 35.43, 10.5],
  [200, 219.1, 4.8, 25.37, 39.42, 5.4],
  [200, 219.1, 6.4, 33.57, 29.79, 7.3],
  [250, 273.1, 4.8, 31.76, 31.46, 4.4],
  [300, 323.9, 7.1, 55.47, 18.03, 5.4],
  [300, 323.9, 9.5, 73.65, 13.58, 7.3],
];

export const hollowTable = {
  square: [
    ["16 X 16", 16.0, 16.0, 1.8, 0.83, 1204],
    ["16 X 16", 16.0, 16.0, 2.4, 1.07, 935],
    ["19 X 19", 19.0, 19.0, 1.8, 0.99, 1010],
    ["19 X 19", 19.0, 19.0, 2.4, 1.29, 775],
    ["25 X 25", 25.0, 25.0, 2.6, 1.69, 592],
    ["25 X 25", 25.0, 25.0, 3.2, 1.98, 505],
    ["30 X 30", 30.0, 30.0, 2.6, 2.1, 476],
    ["30 X 30", 30.0, 30.0, 3.2, 2.49, 402],
    ["30 X 30", 30.0, 30.0, 4.0, 2.94, 340],
    ["40 X 40", 40.0, 40.0, 2.6, 2.92, 342],
    ["40 X 40", 40.0, 40.0, 3.2, 3.49, 287],
    ["40 X 40", 40.0, 40.0, 3.6, 3.85, 260],
    ["40 X 40", 40.0, 40.0, 4.0, 4.2, 238],
    ["50 X 50", 50.0, 50.0, 2.9, 4.35, 230],
    ["50 X 50", 50.0, 50.0, 3.2, 4.77, 210],
    ["72 X 72", 72.0, 72.0, 3.2, 6.71, 149],
    ["72 X 72", 72.0, 72.0, 4.0, 8.22, 122],
    ["72 X 72", 72.0, 72.0, 4.8, 9.66, 104],
    ["88.9 X 88.9", 88.9, 88.9, 3.6, 9.38, 107],
    ["88.9 X 88.9", 88.9, 88.9, 4.5, 11.52, 87],
    ["88.9 X 88.9", 88.9, 88.9, 4.9, 12.44, 71],
    ["91.5 X 91.5", 91.5, 91.5, 3.6, 9.67, 103],
    ["91.5 X 91.5", 91.5, 91.5, 4.5, 11.88, 84],
    ["91.5 X 91.5", 91.5, 91.5, 5.4, 14.01, 71],
    ["113.5 X 113.5", 113.5, 113.5, 4.8, 15.92, 63],
    ["113.5 X 113.5", 113.5, 113.5, 5.4, 17.74, 56],
    ["132 X 132", 132.0, 132.0, 4.8, 18.71, 53],
    ["132 X 132", 132.0, 132.0, 5.4, 20.88, 48],
    ["150 X 150", 150.0, 150.0, 5.0, 22.26, 45],
  ],
  rectangle: [
    ["30 X 20", 30.0, 20.0, 2.0, 1.47, 680],
    ["40 X 20", 40.0, 20.0, 2.0, 1.78, 562],
    ["50 X 25", 50.0, 25.0, 2.9, 2.98, 336],
    ["50 X 25", 50.0, 25.0, 3.2, 3.24, 309.0],
    ["60 X 40", 60.0, 40.0, 2.9, 4.12, 243.0],
    ["66 X 33", 66.0, 33.0, 2.9, 4.07, 246.0],
    ["66 X 33", 66.0, 33.0, 3.6, 4.93, 203.0],
    ["66 X 33", 66.0, 33.0, 4.5, 5.95, 168.0],
    ["70 X 30", 70.0, 30.0, 2.9, 4.12, 243.0],
    ["70 X 30", 70.0, 30.0, 3.2, 4.5, 222.0],
    ["70 X 30", 70.0, 30.0, 4.0, 5.45, 183.0],
    ["76 X 38", 76.0, 38.0, 2.5, 4.32, 231.0],
    ["80 X 40", 80.0, 40.0, 2.9, 5.03, 199.0],
    ["80 X 40", 80.0, 40.0, 3.2, 5.5, 182.0],
    ["80 X 40", 80.0, 40.0, 4.0, 6.71, 149.0],
    ["96 X 48", 96.0, 48.0, 3.2, 6.71, 149.0],
    ["96 X 48", 96.0, 48.0, 4.0, 8.22, 122.0],
    ["96 X 48", 96.0, 48.0, 4.8, 9.66, 104.0],
    ["122 X 61", 122.0, 61.0, 3.6, 9.67, 103.0],
    ["122 X 61", 122.0, 61.0, 4.5, 11.88, 84.0],
    ["122 X 61", 122.0, 61.0, 5.4, 14.01, 71.0],
    ["145 X 82", 145.0, 82.0, 4.8, 15.92, 63.0],
    ["145 X 82", 145.0, 82.0, 5.4, 17.74, 56.0],
    ["172 X 92", 172.0, 92.0, 4.8, 18.71, 53.0],
    ["172 X 92", 172.0, 92.0, 5.4, 20.88, 48.0],
  ],
  other: [
    ["YST-210", 330, 210, 12, 20],
    ["YST-240", 410, 240, 10, 15],
  ],
};

export const structurtalScaffTable = [
  [15, "L", 21.3, 2.0, 0.95, 1056, 0.99, 1010],
  [15, "M", 21.3, 2.6, 1.21, 826, 1.26, 794],
  [15, "H", 21.3, 3.2, 1.44, 694, 1.49, 671],
  [20, "L", 26.9, 2.3, 1.38, 725, 1.44, 694],
  [20, "M", 26.9, 2.6, 1.56, 641, 1.62, 617],
  [20, "H", 26.9, 3.2, 1.87, 535, 1.93, 518],
  [25, "L", 33.7, 2.6, 1.98, 505, 2.05, 488],
  [25, "M", 33.7, 3.2, 2.41, 415, 2.4, 403],
  [25, "H", 33.7, 4.0, 2.93, 341, 3.0, 333],
  [32, "L", 42.4, 2.6, 2.25, 394, 2.64, 379],
  [32, "M", 42.4, 3.2, 3.1, 323, 3.2, 313],
  [32, "H", 42.4, 4.0, 3.79, 264, 3.88, 258],
  [40, "L", 48.3, 2.9, 3.23, 310, 3.34, 299],
  [40, "M", 48.3, 3.2, 3.56, 281, 3.67, 272],
  [40, "H", 48.3, 4.0, 4.37, 229, 4.48, 223],
  [50, "L", 60.3, 2.9, 4.08, 245, 4.22, 237],
  [50, "M", 60.3, 3.6, 5.03, 199, 5.17, 193],
  [50, "H", 60.3, 4.5, 6.19, 162, 6.33, 158],
  [65, "L", 76.1, 3.2, 5.71, 175, 5.89, 170],
  [65, "M", 76.1, 3.6, 6.42, 156, 6.6, 152],
  [65, "H", 76.1, 4.5, 7.93, 126, 8.11, 123],
  [80, "L", 88.9, 3.2, 6.72, 149, 6.93, 144],
  [80, "M", 88.9, 4.0, 8.36, 120, 8.57, 117],
  [80, "H", 88.9, 4.8, 9.9, 101, 10.1, 99],
  [90, "L", 101.6, 3.6, 8.7, 115, 8.94, 112],
  [90, "M", 101.6, 4.5, 9.63, 104, 9.87, 101],
  [90, "H", 101.6, 5.4, 11.5, 87, 11.74, 85],
  [100, "L", 114.3, 4.5, 9.75, 103, 10.02, 100],
  [100, "M", 114.3, 4.8, 12.2, 82, 12.47, 80],
  [100, "H", 114.3, 5.4, 14.5, 69, 14.77, 68],
  [110, "L", 127, 4.5, 13.6, 74, 13.9, 72],
  [110, "M", 127, 4.8, 14.5, 69, 14.8, 68],
  [110, "H", 127, 5.4, 16.2, 62, 16.5, 61],
  [125, "L", 139.7, 4.5, 15.0, 67, 15.33, 65],
  [125, "M", 139.7, 4.8, 15.9, 63, 16.23, 62],
  [125, "H", 139.7, 5.4, 17.9, 56, 18.23, 55],
  [135, "L", 152.4, 4.5, 16.4, 61, 16.76, 60],
  [135, "M", 152.4, 4.8, 17.5, 57, 17.86, 56],
  [135, "H", 152.4, 5.4, 19.6, 51, 19.96, 50],
  [150, "L", 165.1, 4.5, 17.8, 56, 18.2, 55],
  [150, "M", 165.1, 4.8, 18.9, 53, 19.29, 52],
  [150, "H", 165.1, 5.4, 21.3, 47, 21.69, 46],
  [150, "L", 168.3, 4.5, 18.2, 55, 18.6, 54],
  [150, "M", 168.3, 4.8, 19.4, 52, 19.8, 51],
  [150, "H1", 168.3, 5.4, 21.7, 46, 22.1, 45],
  [150, "H2", 168.3, 6.3, 25.2, 40, 25.6, 39],
  [175, "L", 193.7, 4.8, 22.4, 45, 22.86, 44],
  [175, "M", 193.7, 5.4, 25.1, 40, 25.56, 39],
  [175, "H", 193.7, 4.9, 27.3, 37, 27.76, 36],
  [200, "L", 219.1, 4.8, 25.4, 39, 25.93, 39],
  [200, "M", 219.1, 5.6, 29.5, 34, 30.03, 33],
  [200, "H", 219.1, 5.9, 31.0, 32, 31.52, 32],
  [250, "H", 273, 38.9, 38.9, 26, 39.56, 25],
  [300, "H", 323.9, 49.3, 49.3, 20, 50.24, 20],
  [350, "H", 355.6, 68.6, 68.6, 14, 69.88, 14.3],
];

export const idlersTable = {
  troughed: [
    [76.1, 3.65, 6.52, 153],
    [76.1, 4.5, 7.95, 126],
    [88.9, 4.05, 8.47, 118],
    [88.9, 4.85, 10.05, 100],
    [88.9, 6.3, 12.83, 78],
    [101.6, 4.05, 9.74, 103],
    [101.6, 5.4, 11.57, 86],
    [101.6, 6.3, 14.81, 68],
    [114.3, 4.05, 12.19, 82],
    [114.3, 5.4, 14.5, 69],
    [114.3, 6.3, 16.78, 60],
    [127.0, 4.05, 13.6, 74],
    [127.0, 4.85, 14.61, 68],
    [127.0, 5.4, 16.19, 62],
    [127.0, 6.3, 18.75, 53],
    [139.7, 4.05, 15.0, 67],
    [139.7, 4.85, 16.13, 62],
    [139.7, 5.4, 17.89, 56],
    [139.7, 6.3, 20.73, 48],
    [152.4, 4.05, 16.4, 61],
    [152.4, 4.85, 17.65, 57],
    [152.4, 5.4, 19.58, 51],
    [152.4, 6.3, 22.7, 44],
    [159.0, 4.05, 17.1, 58],
    [159.0, 4.85, 18.44, 54],
    [159.0, 5.4, 20.46, 49],
    [159.0, 6.3, 23.72, 42],
    [165.1, 4.05, 17.8, 56],
    [165.1, 4.85, 19.17, 52],
    [165.1, 5.4, 21.27, 47],
    [165.1, 6.3, 24.67, 41],
    [168.3, 4.05, 18.2, 55],
    [168.3, 4.85, 19.55, 51],
    [168.3, 5.4, 21.69, 46],
    [168.3, 6.3, 25.17, 40],
    [193.7, 5.4, 25.1, 40],
    [193.7, 6.3, 29.12, 34],
    [193.7, 7.1, 32.67, 31],
    [219.1, 5.4, 28.5, 35],
    [219.1, 6.3, 33.06, 30],
    [219.1, 7.1, 37.12, 27],
    [219.1, 7.1, 37.12, 27],
  ],
  water_wells: [
    [100, 114.3, 5, 13.48, 74.2, "-------", "-------", "-------"],
    [100, 114.3, 5.4, 14.5, 69, 5.4, 65.27, 67],
    [100, 114.3, 6, 16.02, 62.4, 6, 59.3, 60.9],
    [100, 114.3, 6.4, 17.03, 58.72, 6.4, 55.6, 56.98],
    [125, 141.3, 5, 16.8, 29.52, 5.4, 51.3, 53.27],
    [125, 141.3, 5.4, 18.1, 55.24, 6, 46.8, 48.45],
    [125, 141.3, 6, 20.02, 50, 6.4, 44.19, 45.55],
    [125, 141.3, 6.4, 21.29, 47, 7.1, 40.25, 41.36],
    [150, 168.3, 5, 20.13, 49.68, "-------", "-------", "-------"],
    [150, 168.3, 5.4, 21.69, 46.1, 5.4, 42.2, 43.89],
    [150, 168.3, 6, 24.01, 41.65, 6, 38.39, 39.93],
    [150, 168.3, 6.4, 25.55, 39.14, 6.4, 36.18, 37.44],
    [150, 168.3, 7.1, 28.22, 35.43, 7.1, 33, 34.17],
    [175, 193.7, 5.4, 25.07, 39.9, "-------", "-------", "-------"],
    [175, 193.7, 6, 27.77, 36, 6.4, 31.19, 32.45],
    [175, 193.7, 6.4, 29.56, 33.83, 7.1, 28.43, 29.48],
    [175, 193.7, 7.1, 32.67, 30.6, 8, 25.55, 26.4],
    [200, 219.1, 5.4, 28.46, 35.13, "-------", "-------", "-------"],
    [200, 219.1, 6, 31.53, 31.72, 6, 27.59, 28.65],
    [200, 219.1, 6.4, 33.57, 29.8, 6.4, 25.19, 25.96],
    [200, 219.1, 7.1, 37.12, 26.94, 7.1, 23.88, 24.8],
    [200, 219.1, 7.5, 39.13, 25.55, 8, 22.56, 23.25],
    [200, 219.1, 8, 41.65, 24, "", "", ""],
    [250, 273.1, 7.1, 46.57, 21.47, "-------", "-------", "-------"],
    [250, 273.1, 7.5, 49.12, 20.36, "-------", "-------", "-------"],
    [250, 273.1, 8, 52.3, 19.1, 8, 17.64, 18.39],
    [250, 273.1, 9, 58.61, 17, 10, 14.6, 14.9],
    [250, 273.1, 10, 64.9, 15.41, "", "", ""],
    [300, 323.9, 7.1, 55.47, 18, "-------", "-------", "-------"],
    [300, 323.9, 7.5, 58.52, 17.1, 8, 15.04, 15.53],
    [300, 323.9, 8, 62.32, 16.05, 9, 13.5, 13.89],
    [300, 323.9, 9, 69.9, 14.3, 10, 12.26, 12.58],
    [300, 323.9, 10, 77.41, 12.92, "", "", ""],
    [350, 355.6, 8, 68.57, 14.58, 8, "-------", "-------"],
    [350, 355.6, 9, 76.92, 13, 9, "", ""],
    [350, 355.6, 10, 85.22, 11.73, 10, "", ""],
  ],
};

export const waterGasTable = [
  [15, "L", 21.0, 21.4, 2.0, 0.947, 1056, 0.956, 1046, 27.0, 37.0],
  [15, "M", 21.0, 21.8, 2.6, 1.205, 826, 1.22, 820, 27.0, 37.0],
  [15, "H", 21.0, 21.8, 3.2, 1.436, 694, 1.45, 690, 27.0, 37.0],
  [20, "L", 26.4, 26.9, 2.3, 1.381, 725, 1.39, 719, 32.5, 39.0],
  [20, "M", 26.5, 27.3, 2.6, 1.558, 641, 1.57, 637, 32.5, 39.0],
  [20, "H", 26.5, 27.3, 3.2, 1.87, 535, 1.88, 532, 32.5, 39.0],
  [25, "L", 33.2, 33.8, 2.6, 1.981, 505, 2.0, 500, 39.5, 46.0],
  [25, "M", 33.3, 34.2, 3.2, 2.411, 415, 2.43, 411, 39.5, 46.0],
  [25, "H", 33.3, 34.2, 4.0, 2.935, 341, 2.95, 339, 39.5, 46.0],
  [32, "L", 41.9, 42.5, 2.6, 2.539, 394, 2.57, 389, 49.0, 51.0],
  [32, "M", 42.0, 42.9, 3.2, 3.097, 323, 3.13, 319, 49.0, 51.0],
  [32, "H", 42.0, 42.9, 4.0, 3.793, 264, 3.82, 262, 49.0, 51.0],
  [40, "L", 47.8, 48.4, 2.9, 3.232, 310, 3.27, 306, 56.0, 51.0],
  [40, "M", 47.9, 48.8, 3.2, 3.563, 281, 3.6, 278, 56.0, 51.0],
  [40, "H", 47.9, 48.8, 4.0, 4.375, 229, 4.41, 227, 56.0, 51.0],
  [50, "L", 59.6, 60.2, 2.9, 4.076, 245, 4.15, 241, 68.0, 60.0],
  [50, "M", 59.7, 60.8, 3.6, 5.029, 199, 5.1, 196, 68.0, 60.0],
  [50, "H", 59.7, 60.8, 4.5, 6.187, 162, 6.26, 160, 68.0, 60.0],
  [65, "L", 75.2, 76.0, 3.2, 5.713, 175, 5.83, 172, 84.0, 69.0],
  [65, "M", 75.3, 76.6, 3.6, 6.423, 156, 6.54, 153, 84.0, 69.0],
  [65, "H", 75.3, 76.6, 4.5, 7.929, 126, 8.05, 124, 84.0, 69.0],
  [80, "L", 87.9, 88.7, 3.2, 6.715, 149, 6.89, 145, 98.0, 75.0],
  [80, "M", 88.0, 89.5, 4.0, 8.36, 120, 8.53, 117, 98.0, 75.0],
  [80, "H", 88.0, 89.5, 4.8, 9.937, 101, 10.1, 99, 98.0, 75.0],
  [100, "L", 113.0, 113.9, 3.6, 9.752, 103, 10.0, 100, 124.0, 87.0],
  [100, "M", 113.1, 115.0, 4.5, 12.157, 82, 12.5, 80, 124.0, 87.0],
  [100, "H", 113.1, 115.0, 5.4, 14.468, 69, 14.8, 68, 124.0, 87.0],
  [125, "M", 138.5, 140.8, 4.8, 15.962, 63, 16.4, 61, 151.0, 96.0],
  [125, "H", 138.5, 140.8, 5.4, 17.877, 56, 18.4, 54, 151.0, 96.0],
  [150, "M", 163.9, 166.5, 4.8, 18.986, 53, 19.5, 51, 178.0, 96.0],
  [150, "H", 163.9, 166.5, 5.4, 21.28, 47, 21.9, 46, 178.0, 96.0],
];
