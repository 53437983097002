import React, { useRef, useState } from "react";
import "./Contact.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Heading from "../../Components/Heading/Heading";
import Loader from "../../Components/Loader/Loader";

const Contact = (props) => {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadingMapHandler = () => setIsLoading(false);

  const handleSubmit = () => {
    setTimeout(() => {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      messageRef.current.value = "";
    }, 5000);
  };

  return (
    <div id="contact">
      <Heading
        highlightText="Contact Us"
        subTitleText="For any query or details about anything, please feel free to contact us, you can mail us directly or can call us on the number below. We will be very happy to serve you."
      />
      <form
        // action="https://formspree.io/f/myyryelo"
        method="POST"
        name="contact_page"
        className="contact__form"
        onSubmit={() => handleSubmit()}
      >
        <div className="form__container">
          <h4>SEND US A MESSAGE</h4>
          <div className="box">
            <label htmlFor="firstname">First name</label> <br />
            <input
              ref={firstNameRef}
              id="firstname"
              name="firstname"
              type="text"
              placeholder="First name"
              required
            />
          </div>
          <div className="box">
            <label htmlFor="lastname">Last name</label> <br />
            <input
              ref={lastNameRef}
              id="lastname"
              name="lastname"
              type="text"
              placeholder="Last name"
              required
            />
          </div>

          <div className="email">
            <label htmlFor="emailid">Email</label> <br />
            <input
              ref={emailRef}
              id="emailid"
              type="email"
              name="email"
              placeholder="Enter your mail"
              required
            />
          </div>
          <div className="message">
            <label htmlFor="query">Message</label> <br />
            <textarea name="message" id="query" ref={messageRef}></textarea>
          </div>
          <input type="submit" value="Submit" className="submit--here" />
        </div>
        <div className="map_container">
          {isLoading && <Loader />}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.589518457475!2d77.4913329!3d28.612088699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cedc84c80e3ed%3A0x5b6b33e47536a39b!2sIndus%20Tube%20Limited!5e0!3m2!1sen!2sin!4v1719979642783!5m2!1sen!2sin"
            style={{ border: "0" }}
            title="Home Address"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            onLoad={loadingMapHandler}
          ></iframe>
        </div>
      </form>

      <div className="icon">
        <div className="location">
          <a
            style={{ marginTop: "25px" }}
            href="https://www.google.com/maps/place/Indus+Tube+Limited/@28.6120887,77.4913329,17z/data=!3m1!4b1!4m6!3m5!1s0x390cedc84c80e3ed:0x5b6b33e47536a39b!8m2!3d28.6120887!4d77.4913329!16s%2Fg%2F11f2wbg5d9?entry=ttu"
          >
            <div className="box">
              <LocationOnIcon />
            </div>
          </a>

          <span className="contact--info">Our Location</span>
          <span className="details">{props.location}</span>
        </div>

        <div className="phone">
          <a href="tel:+011-41572981">
            <div className="box">
              <LocalPhoneIcon />
            </div>
          </a>

          <span className="contact--info">Our Phone</span>
          <span className="details">+91 {props.phone} </span>
        </div>

        <div className="emailicon">
          <a href="mailto:info@industubes.com?&amp;subject=Hey There, I am a user from your website.">
            <div className="box">
              <EmailIcon />
            </div>
          </a>
          <span className="contact--info">Our Email id</span>
          <span className="details">{props.email}</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
