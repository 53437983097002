import React from "react";
import "./Footer.css";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import XIcon from "@mui/icons-material/X";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_redirects_container">
        <div className="footer__section">
          <img src="./logo.png" alt="logo" />
          <span className="footer___headline">
            India’s largest structural steel tubes & pipes manufacturing
            company.
          </span>
          <div className="footer_redirect_box">
            <CallIcon className="mui___icons" />
            <a href="tel:+011-41572981" target="_blank" rel="noreferrer">
              011-41572981, 43542981
            </a>
          </div>
          <div className="footer_redirect_box">
            <MailIcon className="mui___icons" />
            <a
              href="mailto:info@industubes.com?&amp;subject=User from Website"
              target="_blank"
              rel="noreferrer"
            >
              info@industubes.com
            </a>
          </div>
          <div className="footer_redirect_box">
            <HomeIcon className="mui___icons" />
            <a
              href="https://www.google.com/maps/place/Indus+Tube+Limited/@28.6120887,77.4913329,17z/data=!3m1!4b1!4m6!3m5!1s0x390cedc84c80e3ed:0x5b6b33e47536a39b!8m2!3d28.6120887!4d77.4913329!16s%2Fg%2F11f2wbg5d9?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              GD - ITL Tower, Top Floor, B - 8, Netaji Subhash Place Pitampura,
              New Delhi-110034
            </a>
          </div>
        </div>
        <div className="footer__section footer__nav___items">
          <h4 className="footer___section__headlines">Quick Links</h4>

          <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/about">About</Link>
          </div>

          <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/ms-pipes">Products</Link>
          </div>

          <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/group-activities">Group Activities</Link>
          </div>

          <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/certifications">Certifications</Link>
          </div>

          <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="footer__section footer__nav___items">
          <h4 className="footer___section__headlines">Other Links</h4>

          {/* <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/" target="_blank" rel="noreferrer">
              Catlogue Headline
            </Link>
          </div> */}

          {/* <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link to="/" target="_blank" rel="noreferrer">
              Catlogue Headline
            </Link>
          </div> */}

          <div className="footer_redirect_box">
            <PlayArrowIcon className="mui___icons" />
            <Link
              to="./terms_and_condition.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
        <div className="footer__section footer__nav___items">
          <h4 className="footer___section__headlines">Social Media</h4>
          <div className="social__media_container">
            <a
              href="https://www.linkedin.com/company/indus-tubes-limited/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon className="social_media_icon" />
            </a>
            {/* <a href="#" target="_blank" rel="noreferrer">
              <WhatsAppIcon className="social_media_icon" />
            </a> */}
            <a
              href="https://www.facebook.com/profile.php?id=61571271905604"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon className="social_media_icon" />
            </a>
            {/* <a href="#" target="_blank" rel="noreferrer">
              <XIcon className="social_media_icon" />
            </a> */}
          </div>
        </div>
      </div>
      <p className="copyright_paragraph">
        Copyright &copy; {new Date().getFullYear()} Indus Tubes Limited | All
        Rights Reserved!
      </p>
    </footer>
  );
};

export default Footer;
