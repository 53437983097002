import React from "react";
import "./Home.css";
import Heading from "../../Components/Heading/Heading";
import Slider from "react-slick";
import {
  inspectedSliderData,
  approvedSliderData,
} from "./../../assets/company";
import { Link } from "react-router-dom";

const Home = () => {
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    initialSlide: 0,
    slidesToShow: 7,
    slidesToScroll: 1,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          autoplaySpeed: 1000,
          speed: 1000,
        },
      },
    ],
  };

  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    initialSlide: 0,
    slidesToShow: 7,
    slidesToScroll: 1,
    cssEase: "linear",
    pauseOnHover: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          autoplaySpeed: 1000,
          speed: 1000,
        },
      },
    ],
  };

  const sliderMain = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <div className="home__container">
      {/* MAIN CAROUSEL */}
      <div className="main_carousel_container">
        <Slider {...sliderMain}>
          <img
            className="slider___img"
            src="./images/main_carousel/1.png"
            alt="main slide 1"
          />
          <img
            className="slider___img"
            src="./images/main_carousel/2.jpg"
            alt="main slide 2"
          />
          <img
            className="slider___img"
            src="./images/main_carousel/3.jpg"
            alt="main slide 3"
          />
          <img
            className="slider___img"
            src="./images/main_carousel/4.jpg"
            alt="main slide 4"
          />
          <img
            className="slider___img"
            src="./images/main_carousel/5.jpg"
            alt="main slide 5"
          />
          <img
            className="slider___img"
            src="./images/main_carousel/6.jpg"
            alt="main slide 5"
          />
        </Slider>
      </div>

      {/* Overview Section */}
      <div className="overview__section">
        <h2 className="wow fadeIn">WELCOME TO INDUS TUBES LIMITED</h2>
        <p className="wow fadeIn">
          Indus Tubes Limited, established in 1987, is a reputable manufacturer
          of high-quality pipes and tubes. Founded by visionary leaders Sh. G.
          K. Jaju, Sh. I. P. Jain, Sh. B. L. Gupta, and Sh. Kailash Jajodia, the
          company began with a single mill and has since grown into a major
          player in the industry with four fully equipped manufacturing units.
          The company has earned recognition for its state-of-the-art facilities
          and unwavering commitment to quality, making it a trusted name among
          government, semi-government, and private corporations.
        </p>
        <Link className="about___link wow fadeIn" to="/about">
          About Us
        </Link>
      </div>

      {/* INFRASTRUCTURE SECTION */}
      <div className="infrastructure_section">
        <Heading
          highlightText="Infrastructure and Facilities"
          subTitleText="Indus Tubes Limited has expanded its infrastructure significantly since its inception. The four mills are equipped with modern facilities, ensuring high production efficiency and superior quality. Key facilities include:"
        />
        <div className="infrastructure_sec_card_container">
          <div className="infrastructure_sec_card wow fadeInDown">
            <img src="./images/infrastructure/1.webp" alt="hydro" />
            <h5>Hydro Testing</h5>
            <p>
              Ensures that pipes are leak-proof and can withstand high pressure.
            </p>
          </div>

          <div className="infrastructure_sec_card wow fadeInDown">
            <img src="./images/infrastructure/2.jpg" alt="threading" />
            <h5>Threading</h5>
            <p>Provides precision threading to meet customer specifications.</p>
          </div>

          <div className="infrastructure_sec_card wow fadeInDown">
            <img src="./images/infrastructure/3.webp" alt="lab" />
            <h5>Testing Lab</h5>
            <p>
              Advanced equipment for rigorous quality checks and compliance
              testing.
            </p>
          </div>

          <div className="infrastructure_sec_card wow fadeInDown">
            <img src="./images/infrastructure/4.jpg" alt="galvanizing" />
            <h5>Galvanizing</h5>
            <p>Hot-dip galvanization for corrosion-resistant pipes.</p>
          </div>

          <div className="infrastructure_sec_card wow fadeInDown">
            <img src="./images/infrastructure/5.jpg" alt="powder" />
            <h5>Powder Coating</h5>
            <p>
              Provides an additional layer of protection and aesthetic appeal.
            </p>
          </div>
        </div>
      </div>

      {/* WHAT MAKES US DIFFERENT */}
      <div className="our_quality_section">
        <h3 className="quality_top__headline">what makes us different</h3>
        <br />
        <h3 className="quality_main__headline">Our Strengths</h3>
        <br />

        <div className="quality_sec_card_container">
          <div className="quality_sec_card wow zoomInDown">
            <h4>Comprehensive Facilities</h4>
            <p>End-to-end manufacturing capabilities under one roof.</p>
          </div>

          <div className="quality_sec_card wow zoomInDown">
            <h4>Quality Assurance</h4>
            <p>Rigorous testing and adherence to international standards.</p>
          </div>

          <div className="quality_sec_card wow zoomInDown">
            <h4>Environmental and Social Responsibility</h4>
            <p>
              ISO 14001 and SA 8000 certifications highlight a commitment to
              sustainability and ethical practices.
            </p>
          </div>

          <div className="quality_sec_card wow zoomInDown">
            <h4>Diverse Product Range</h4>
            <p>Pipes available for various applications and industries.</p>
          </div>

          <div className="quality_sec_card wow zoomInDown">
            <h4>Strong Market Reputation</h4>
            <p>Trusted by both government and private sectors.</p>
          </div>
        </div>
      </div>

      {/* OPPORTUNITIES FOR GROWTH */}
      <div className="opportunity__section">
        <Heading highlightText="Opportunities for Growth" />

        <div className="opportunity_sec_content">
          <div className="opportunity_sec_card wow fadeIn">
            <h4>Global Expansion</h4>
            <p>
              Leveraging its certifications and quality standards to tap into
              international markets.
            </p>
          </div>

          <div className="opportunity_sec_card wow fadeIn">
            <h4>Innovation</h4>
            <p>
              Investing in R&D to develop new products, such as high-strength or
              eco-friendly pipes.
            </p>
          </div>

          <div className="opportunity_sec_card wow fadeIn">
            <h4>Digital Integration</h4>
            <p>
              Implementing advanced digital tools for supply chain and customer
              relationship management.
            </p>
          </div>
        </div>
      </div>

      {/* CERTIFICATIONS SECTION */}
      {/* <div className="certifications____sec">
        <Heading
          highlightText="Certifications"
          subTitleText="The company’s dedication to quality and sustainable practices is evident through its certifications: These certifications underline the company’s commitment to maintaining high standards in manufacturing, environmental responsibility, and social ethics."
        />

        <div className="certificates___display">
          <h2 title="Assurance of compliance with Indian Standards.">
            ISI Marking
          </h2>

          <h2 title="Certification for quality management systems.">
            ISO 9001
          </h2>

          <h2 title="Certification for environmental management systems.">
            ISO 14001 (2015)
          </h2>

          <h2
            title=" Certification for social accountability, highlighting ethical
              practices in operations."
          >
            SA 8000 (2014)
          </h2>
        </div>
      </div> */}

      {/* PRODUCT RANGE SECTION */}
      <div className="product__range__sec">
        <Heading
          highlightText="Product Range and Standards"
          subTitleText="Indus Tubes Limited manufactures pipes that adhere to a wide range of standards, meeting diverse industry needs. The available standards include:"
        />

        <div className="product__range__cards__container">
          <Link
            to="/steel-tubes-for-water-gas-steam-air"
            className="product__range__card wow slideInDown"
          >
            <h2>IS-1239</h2>
            <div>
              Steel tubes suitable for structural, mechanical, and general
              engineering purposes.
            </div>
            <div className="redirect__btn">Know More</div>
          </Link>

          <Link
            to="/steel-tubes-for-water-gas-and-sewerage"
            className="product__range__card wow slideInDown"
          >
            <h2>IS-3589</h2>
            <div>Pipes for water, gas, and sewerage systems.</div>
            <div className="redirect__btn">Know More</div>
          </Link>

          <Link
            to="/steel-tubes-for-idlers-and-troughed"
            className="product__range__card wow slideInDown"
          >
            <h2>IS-4270</h2>
            <div>Pipes used in soil and waste discharge systems.</div>
            <div className="redirect__btn">Know More</div>
          </Link>

          <Link
            to="/steel-tubes-for-structural-scffolding"
            className="product__range__card wow slideInDown"
          >
            <h2>IS-1161</h2>
            <div>Steel tubes for structural purposes.</div>
            <div className="redirect__btn">Know More</div>
          </Link>

          <Link
            to="/steel-tubes-for-idlers-and-troughed"
            className="product__range__card wow slideInDown"
          >
            <h2>IS-9295</h2>
            <div>Pipes for industrial applications.</div>
            <div className="redirect__btn">Know More</div>
          </Link>

          <Link
            to="/steel-tubes-for-transport-system-for-png"
            className="product__range__card wow slideInDown"
          >
            <h2>IS/ISO 3183</h2>
            <div>
              Standards for pipeline transportation systems in the petroleum and
              natural gas industries.
            </div>
            <div className="redirect__btn">Know More</div>
          </Link>

          <Link to="/" className="product__range__card wow slideInDown">
            <h2>ET/OHE/11(5/89)-RDSO</h2>
            <div>Railway electrification specifications.</div>
            <div className="redirect__btn">Know More</div>
          </Link>
        </div>
      </div>

      {/* CLIENTELE SECTION */}
      <div className="clientele__section our_quality_section">
        <h3 className="quality_main__headline">Clientele</h3>
        <br />

        <div className="quality_sec_card_container">
          <div className="quality_sec_card wow zoomInDown">
            <h4>Government Organizations</h4>
            <p>Supplying infrastructure projects and public utilities.</p>
          </div>

          <div className="quality_sec_card wow zoomInDown">
            <h4>Semi-Government Bodies</h4>
            <p>Meeting the needs of public-private partnerships.</p>
          </div>

          <div className="quality_sec_card wow zoomInDown">
            <h4>Private Corporations</h4>
            <p>Catering to diverse industrial and commercial requirements.</p>
          </div>
        </div>

        <p className="clientele___content wow fadeInUp">
          This diverse clientele reflects the company’s ability to deliver
          quality products across different sectors
        </p>
      </div>

      {/* INSPECTED BY CONTAINER */}
      <div className="inspected_container">
        <br />
        <Heading
          highlightText="Inspected By"
          subTitleText="Our Company is Inspected by the below listed Companies"
        />
        <div className="company__slider__container">
          <Slider {...settings1}>
            {inspectedSliderData.map((el, ind) => (
              <div className="slide" key={ind}>
                <img
                  className="comp_img"
                  src={el.imgLink}
                  alt={`company ${ind + 1}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* APPROVED BY CONTAINER */}
      <div className="approved_container">
        <Heading
          highlightText="Approved By"
          subTitleText="Our Company is Approved by the below listed Companies"
        />

        <div className="company__slider__container">
          <Slider {...settings2}>
            {approvedSliderData.map((el, ind) => (
              <div className="slide" key={ind}>
                <img
                  className="comp_img"
                  src={el.imgLink}
                  alt={`company ${ind + 1}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* CONCLUSION CONTAINER */}
      <div className="conclusion__container wow fadeIn">
        Indus Tubes Limited is a well-established leader in the pipe
        manufacturing industry, known for its robust infrastructure, quality
        assurance, and commitment to customer satisfaction. Its certifications
        and adherence to a wide range of standards make it a reliable choice for
        government, semi-government, and private entities. With its focus on
        innovation and sustainability, the company is well-positioned to achieve
        even greater heights in the future.
      </div>
    </div>
  );
};

export default Home;
