import React from "react";
import "./Products.css";
import { structurtalScaffTable } from "../../assets/productsTables";

const STStrScf = () => {
  return (
    <div className="products__table_container">
      <div className="table__heading_container">
        <h2 className="table__main_heading">
          STEEL TUBES FOR STRUCTURAL SCFFOLDING
        </h2>
        <h3 className="table__sub_heading">
          CONFORMING TO IS:1161- 1998 GRADE YST 210 & 240
        </h3>
      </div>

      <table className="product__table">
        <tr>
          <th>N.B AND Series (mm)</th>
          <th>Grade (mm) </th>
          <th>Outside Dia. (mm)</th>
          <th>Thickness (mm)</th>
          <th>Nominal Wt Black Tubes Plain End (kg/m)</th>
          <th>Nominal Wt Black Tubes Plain End (m/Tn.)</th>
          <th>Calculated Nominal Wt. Galvd. Tubes Plain End (kg/m)</th>
          <th>Calculated Nominal Wt. Galvd. Tubes Plain End (m/Tn.)</th>
        </tr>

        {structurtalScaffTable.map((el) => {
          return (
            <tr>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{el[2]}</td>
              <td>{el[3]}</td>
              <td>{el[4]}</td>
              <td>{el[5]}</td>
              <td>{el[6]}</td>
              <td>{el[7]}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default STStrScf;
