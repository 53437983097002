export const cert = [
  {
    pdf: "./certificates/c1.pdf",
    coverImg: "/certificates/certImage/c1.jpg",
  },
  {
    pdf: "./certificates/c2.pdf",
    coverImg: "/certificates/certImage/c2.jpg",
  },
  {
    pdf: "./certificates/c3.pdf",
    coverImg: "/certificates/certImage/c3.jpg",
  },
  {
    pdf: "./certificates/c4.pdf",
    coverImg: "/certificates/certImage/c4.jpg",
  },
  {
    pdf: "./certificates/c5.pdf",
    coverImg: "/certificates/certImage/c5.jpg",
  },
  {
    pdf: "./certificates/c6.pdf",
    coverImg: "/certificates/certImage/c6.jpg",
  },
  {
    pdf: "./certificates/c7.pdf",
    coverImg: "/certificates/certImage/c7.jpg",
  },
  {
    pdf: "./certificates/c8.pdf",
    coverImg: "/certificates/certImage/c8.jpg",
  },
  {
    pdf: "./certificates/c9.pdf",
    coverImg: "/certificates/certImage/c9.jpg",
  },
  {
    pdf: "./certificates/c10.pdf",
    coverImg: "/certificates/certImage/c10.jpg",
  },
  {
    pdf: "./certificates/c11.pdf",
    coverImg: "/certificates/certImage/c11.jpg",
  },
  {
    pdf: "./certificates/c12.pdf",
    coverImg: "/certificates/certImage/c12.jpg",
  },
  {
    pdf: "./certificates/c13.pdf",
    coverImg: "/certificates/certImage/c13.jpg",
  },
  {
    pdf: "./certificates/c14.pdf",
    coverImg: "/certificates/certImage/c14.jpg",
  },
  {
    pdf: "./certificates/c15.pdf",
    coverImg: "/certificates/certImage/c15.jpg",
  },
  {
    pdf: "./certificates/c16.pdf",
    coverImg: "/certificates/certImage/c16.jpg",
  },
  {
    pdf: "./certificates/c17.pdf",
    coverImg: "/certificates/certImage/c17.jpg",
  },
  {
    pdf: "./certificates/c18.pdf",
    coverImg: "/certificates/certImage/c18.jpg",
  },
  {
    pdf: "./certificates/c19.pdf",
    coverImg: "/certificates/certImage/c19.jpg",
  },
  {
    pdf: "./certificates/c20.pdf",
    coverImg: "/certificates/certImage/c20.jpg",
  },
  {
    pdf: "./certificates/c21.pdf",
    coverImg: "/certificates/certImage/c21.jpg",
  },
  {
    pdf: "./certificates/c22.pdf",
    coverImg: "/certificates/certImage/c22.jpg",
  },
  {
    pdf: "./certificates/c23.pdf",
    coverImg: "/certificates/certImage/c23.jpg",
  },
  {
    pdf: "./certificates/c24.pdf",
    coverImg: "/certificates/certImage/c24.jpg",
  },
  {
    pdf: "./certificates/c25.pdf",
    coverImg: "/certificates/certImage/c25.jpg",
  },
];
