import React from "react";
import "./Products.css";
import { products } from "../../assets/products";

const GIPipe = () => {
  const data = products.giPipes;

  return (
    <div className="products__container">
      <div className="product__images">
        <img src="./images/products/GI/1.png" alt="pipe 1" />
        <img src="./images/products/GI/2.jpeg" alt="pipe 2" />
      </div>

      <div className="product__content">
        <h2 className="product___main_headline">{data.headline}</h2>
        <p className="product__main_desc">{data.desc}</p>

        {data.content.map((el, ind) => {
          return (
            <div className="product__sub__contents" key={ind}>
              <h2 className="product___sub_main_headline wow fadeInUp">
                {el.headline}
              </h2>

              {el.sub_conents ? (
                el.sub_conents.map((sub_conents, index) => {
                  return (
                    <div
                      className="product_nested_contents wow fadeInUp"
                      key={index}
                    >
                      <h2 className="product__nested_headine">
                        {sub_conents.headline}
                      </h2>
                      <p className="product__nested_desc">{sub_conents.desc}</p>
                    </div>
                  );
                })
              ) : (
                <p className="product__single_content_dec wow fadeInUp">
                  {el.desc}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GIPipe;
