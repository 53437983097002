import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route } from "react-router-dom";
import { groupActivities } from "./assets/group_activities";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Container/Home/Home";
import About from "./Container/About/About";
import GroupActivities from "./Container/Group_Activities/GroupActivities";
import ActivityCard from "./Container/Group_Activities/ActivityCard";
import Certifications from "./Container/Certifications/Certifications";
import Contact from "./Container/Contact/Contact";
import MSPipe from "./Container/Products/MSPipe";
import GIPipe from "./Container/Products/GIPipe";
import STHollow from "./Container/Products/STHollow";
import TransportSystemPng from "./Container/Products/TransportSystemPng";
import STIdlers from "./Container/Products/STIdlers";
import STStrScf from "./Container/Products/STStrScf";
import STGasSewerage from "./Container/Products/STGasSewerage";
import STWaterGas from "./Container/Products/STWaterGas";
import ScrollToTop from "./Components/ScrollToTop";

const App = () => {
  return (
    <>
      <Header />

      <main>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/group-activities" element={<GroupActivities />} />
          <Route path="/certifications" element={<Certifications />} />

          {/* Sub Routes for Group Activities */}
          <Route
            path="/itl-public-school"
            element={<ActivityCard data={groupActivities[0]} />}
          />
          <Route
            path="/itl-education"
            element={<ActivityCard data={groupActivities[1]} />}
          />
          <Route
            path="/shri-siddeeshwar-dham"
            element={<ActivityCard data={groupActivities[2]} />}
          />

          {/* Routes for Products */}
          <Route path="/ms-pipes" element={<MSPipe />} />
          <Route path="/gi-pipes" element={<GIPipe />} />

          {/* Sub Routes for Products */}
          <Route
            path="/steel-tubes-for-water-gas-steam-air"
            element={<STWaterGas />}
          />
          <Route
            path="/steel-tubes-for-structural-scffolding"
            element={<STStrScf />}
          />

          <Route
            path="/steel-tubes-for-transport-system-for-png"
            element={<TransportSystemPng />}
          />
          <Route
            path="/steel-tubes-for-water-gas-and-sewerage"
            element={<STGasSewerage />}
          />
          <Route
            path="/steel-tubes-for-idlers-and-troughed"
            element={<STIdlers />}
          />
          <Route
            path="/steel-tubes-for-hollow-stl-sec"
            element={<STHollow />}
          />

          <Route
            path="/contact"
            element={
              <Contact
                location="GD - ITL Tower, Top Floor, B - 8, Netaji Subhash Place Pitampura, New Delhi-110034"
                phone="011-41572981, 43542981"
                email="info@industubes.com"
              />
            }
          />
        </Routes>
      </main>

      <Footer />
    </>
  );
};

export default App;
