import React from "react";
import { sewerageTable } from "../../assets/productsTables";
import "./Products.css";

const STGasSewerage = () => {
  return (
    <div className="products__table_container">
      <div className="table__heading_container">
        <h2 className="table__main_heading">
          STEEL TUBES FOR WATER GAS AND SEWERAGE
        </h2>
        <h3 className="table__sub_heading">
          CONFORMING TO IS: 3589-2001 GRADE FE-330, 410 & 450
        </h3>
      </div>

      <table className="product__table">
        <tr>
          <th>N.B (mm)</th>
          <th>O.D (mm)</th>
          <th>Wall Thickness (mm)</th>
          <th>Weight (Kg./M)</th>
          <th>Mtr/Tonne</th>
        </tr>

        {sewerageTable.map((el) => {
          return (
            <tr>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{el[2]}</td>
              <td>{el[3]}</td>
              <td>{el[4]}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default STGasSewerage;
