import React, { useEffect, useState } from "react";
import "./Certifications.css";
import Heading from "../../Components/Heading/Heading";
import { cert } from "./../../assets/certificates";
import Loader from "../../Components/Loader/Loader";

const Certifications = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div className="certification__section">
      <Heading
        highlightText="Certifications"
        subTitleText="Our Achievement & Certifications are listed below"
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div className="certificates_container">
          {cert.length > 0 &&
            cert.map((el, ind) => (
              <div className="certificate_box wow slideInDown" key={ind}>
                {/* <h3>{el.name}</h3> */}
                <a href={el.pdf} target="_blank">
                  <img src={el.coverImg} alt={el.name} />
                </a>

                <a className="view_cert_btn" href={el.pdf} target="_blank">
                  View Certificate
                </a>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Certifications;
