import React from "react";
import "./Products.css";
import { idlersTable } from "../../assets/productsTables";

const STIdlers = () => {
  return (
    <div className="products__table_container">
      <div className="table__heading_container">
        <h2 className="table__main_heading">
          STEEL TUBES FOR IDLERS AND TROUGHED BELT CONVERYORS
        </h2>
        <h3 className="table__sub_heading">
          CONFORMING TO IS: 9295 - 1983 GRADE YST 210 & 240
        </h3>
      </div>

      <table className="product__table">
        <tr>
          <th>Outside (mm)</th>
          <th>Thicness (mm)</th>
          <th>Weight (Plain End) (Kg/Mtrs) </th>
          <th>Mtrs/Tonne</th>
        </tr>

        {idlersTable.troughed.map((el) => {
          return (
            <tr>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{el[2]}</td>
              <td>{el[3]}</td>
            </tr>
          );
        })}
      </table>

      <div className="table__heading_container">
        <h2 className="table__main_heading">
          STEEL TUBES USED FOR WATER WELLS
        </h2>
        <h3 className="table__sub_heading">
          CONFORMING TO IS: 4270-2001 GRADE FE 410 & 450
        </h3>
      </div>

      <table className="product__table">
        <tr>
          <th>Size NB (mm)</th>
          <th>Size OD (mm)</th>
          <th>Plain Ends Thickness (mm) </th>
          <th>Plain Ends Mass (Kg/mtr) </th>
          <th>Plain Ends (Mtr/Tonne)</th>
          <th>Screwed & Socketed Ends Thickness (mm)</th>
          <th>Screwed & Socketed Ends for Length 3Mtr (Mtr/Tonne )</th>
          <th>Screwed & Socketed Ends for Length 4 To 7Mtr (Av 6Mtr)</th>
        </tr>

        {idlersTable.water_wells.map((el) => {
          return (
            <tr>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{el[2]}</td>
              <td>{el[3]}</td>
              <td>{el[4]}</td>
              <td>{el[5]}</td>
              <td>{el[6]}</td>
              <td>{el[7]}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default STIdlers;
