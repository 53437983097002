import React from "react";
import "./ZCard.css";

const ZCard = (props) => {
  return (
    <div className={`Z_Card_Section ${props.reverse ? "Z_Card_Reverse" : ""}`}>
      {props && (
        <>
          <div className="Z_Card_Text wow fadeInLeft">
            {props.text.map((el, ind) => (
              <span key={ind} className="Z_Card_Text_Style">
                {el}
              </span>
            ))}
          </div>
          <div className="Z_Card_Image wow fadeInRight">
            <img src={props.imgSrc} alt={props.alt} />
          </div>
        </>
      )}
    </div>
  );
};

export default ZCard;
