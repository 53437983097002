import React from "react";
import "./Products.css";
import { waterGasTable } from "../../assets/productsTables";

const STWaterGas = () => {
  return (
    <div className="products__table_container">
      <div className="table__heading_container">
        <h2 className="table__main_heading">
          STEEL TUBES FOR WATER ,GAS, STEAM & AIR CONFORMING TO IS -1239(PART-1)
          - 2004
        </h2>
      </div>

      <table className="product__table">
        <tr>
          <th>N.B and Series (mm)</th>
          <th>N.B and Series Grade</th>
          <th>Outside Diameter (Min. mm)</th>
          <th>Outside Diameter (Max. mm)</th>
          <th>Wall Thickness (mm)</th>
          <th>Nominal Weight Plain End (Kg/Mt)</th>
          <th>Nominal Weight Plain End (Mtrs/Tonne)</th>
          <th>Nominal Weight Screwed & Socketed (Kg/Mt)</th>
          <th>Nominal Weight Screwed & Socketed (Mtrs/Tonne)</th>
          <th>Sockets Minimum O.D (mm)</th>
          <th>Sockets Minimum length (mm)</th>
        </tr>

        {waterGasTable.map((el, ind) => {
          return (
            <tr key={ind}>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{el[2]}</td>
              <td>{el[3]}</td>
              <td>{el[4]}</td>
              <td>{el[5]}</td>
              <td>{el[6]}</td>
              <td>{el[7]}</td>
              <td>{el[8]}</td>
              <td>{el[9]}</td>
              <td>{el[10]}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default STWaterGas;
