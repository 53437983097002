import React, { useEffect, useState } from "react";
import "./GroupActivities.css";
import Heading from "../../Components/Heading/Heading";
import { Link } from "react-router-dom";
import { groupActivities } from "../../assets/group_activities";
import Loader from "../../Components/Loader/Loader";

const GroupActivities = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div className="activities__container">
      <Heading
        highlightText="Group Activities"
        subTitleText="Diversification into Other Business Activities"
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div className="activities_list">
          {groupActivities.map((el, ind) => {
            return (
              <div className="activities_card wow slideInDown" key={ind}>
                <h2 className="activity_headline">{el.name}</h2>
                <img src={el.imgURI} alt={el.name} />
                <Link className="activity_link" to={el.redirectURL}>
                  View More
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GroupActivities;
